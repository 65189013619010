type SortOptions<T> = {
  field: keyof T
  order: 'asc' | 'desc'
}

export function extractDateValue(value: any): Date | any {
  if (typeof value === 'string') {
    const datePatterns = [
      {
        regex: /^\d{4}-\d{2}-\d{2}$/,                      // YYYY-MM-DD
        format: (v: string) => v,
      },
      {
        regex: /^\d{2}\/\d{2}\/\d{4}$/,                    // MM/DD/YYYY
        format: (v: string) => v.replace(/\//g, '-'),
      },
      {
        regex: /^\d{2}-\d{2}-\d{4}$/,                      // DD-MM-YYYY
        format: (v: string) => {
          const [day, month, year] = v.split('-');
          return `${year}-${month}-${day}`;
        },
      },
      {
        regex: /^\d{4}\/\d{2}\/\d{2}$/,                    // YYYY/MM/DD
        format: (v: string) => v.replace(/\//g, '-'),
      },
      {
        regex: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/,          // YYYY-MM-DD HH:MM
        format: (v: string) => v.replace(' ', 'T'),
      },
      {
        regex: /^\d{2}\/\d{2}\/\d{4} \d{1,2}:\d{2} [APap][Mm]$/, // MM/DD/YYYY HH:MM AM/PM
        format: (v: string) => {
          const [date, time] = v.split(' ');
          const formattedDate = date.replace(/\//g, '-');
          return `${formattedDate} ${time}`;
        },
      }
    ];

    for (const pattern of datePatterns) {
      if (pattern.regex.test(value)) {
        return new Date(pattern.format(value));
      }
    }
  }

  return value;
}

export function sortByFields<T>(array: T[], options: SortOptions<T>[]): T[] {
  const compare = (a: T, b: T): number => {
    for (const option of options) {
      const { field, order } = option;
      const aValue = extractDateValue(a[field]);
      const bValue = extractDateValue(b[field]);

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      }

      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
    }

    return 0;
  };

  return array.slice().sort(compare);
}
export const flattenObject = (obj, parentKey = '', res = {}) => {
  for (let key in obj) {
    const propName = parentKey ? `${parentKey}.${key}` : key;
    
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      flattenObject(obj[key], propName, res); // Flatten nested objects
    } else if (Array.isArray(obj[key])) {
      if (obj[key].every(item => typeof item === 'string')) {
        // Handle string[] by joining elements into a single string
        res[propName] = obj[key].join(', ');
      } else {
        // Flatten arrays with non-string elements
        obj[key].forEach((item, index) => {
          flattenObject(item, `${propName}[${index}]`, res);
        });
      }
    } else {
      // Assign primitive values
      res[propName] = obj[key];
    }
  }
  return res;
};
