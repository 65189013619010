import { BrowserUtils} from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'

const Logout = () => {
  const { instance } = useMsal()

  useEffect(() => {
    instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe()
    })
}, [instance]);

  return(
    <div>Logout</div>
  )
}

export default Logout
