import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig, AxiosRequestConfig } from 'axios'

const configs = {
    development: {
        REACT_APP_CRYSTAL_BALL_API_BASE_URL: 'https://crystal-ball-api-test.azurewebsites.net',
        REACT_APP_CRYSTAL_BALL_API_FUNCTION_KEY: '7I29L4M83yq7lsW4uHwUvckBRRthioglO2x9TdajnC5jAzFuab0NRg==',
    },
    production: {
        REACT_APP_CRYSTAL_BALL_API_BASE_URL: 'https://crystal-ball-api.azurewebsites.net',
        REACT_APP_CRYSTAL_BALL_API_FUNCTION_KEY: '2q09feByZTeo6vuHf52bwzu4azeZGybhFm-8jsamDMz4AzFuqQMPsg==',
    },
};

const getConfig = () => {
    const hostname = window.location.hostname;

    if (hostname == "crystalball.nethealthshops.com") {
        return configs.production;
    } else if (hostname == "crystalball-test.nethealthshops.com" || hostname == "localhost") {
        return configs.development;
    }
};

// Define the configuration for Axios
const axiosInstance = axios.create({
    baseURL: getConfig().REACT_APP_CRYSTAL_BALL_API_BASE_URL,
    timeout: 60000 * 5, // 5 mins
    headers: {
        'Content-Type': 'application/json',
        "x-functions-key": getConfig().REACT_APP_CRYSTAL_BALL_API_FUNCTION_KEY,
    },
})

// Request Interceptor
axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // Add any custom logic or headers before the request is sent
        // Example: Adding an Authorization token
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error: AxiosError) => {
        // Handle request error
        return Promise.reject(error)
    }
)

// Response Interceptor
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // Any status code within the range of 2xx will cause this function to trigger
        return response
    },
    (error: AxiosError) => {
        // Handle response error
        if (error.response) {
            // Server responded with a status code out of the range of 2xx
            console.error('Response Error:', error.response)
        } else if (error.request) {
            // No response was received from the server
            console.error('No Response:', error.request)
        } else {
            // Something else caused the error
            console.error('Error:', error.message)
        }
        return Promise.reject(error)
    }
)

// Utility function for making API calls
export const apiRequest = async <T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    data?: any,
    config?: AxiosRequestConfig
): Promise<T> => {
    try {
        const response: AxiosResponse<T> = await axiosInstance({
            method,
            url,
            data,
            ...config,
        })
        return response.data;
    } catch (error) {
        throw error
    }
}
