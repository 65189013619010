import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ShippingTracking from '../shippingTracking/ShippingTracking';
import IagTracking from '../iagTracking/IagTracking';

function NobodyShouldKnowThis() {
  const [key, setKey] = useState(() => {
    return localStorage.getItem('selectedTab') || 'shippingTracking';
  });

  useEffect(() => {
    localStorage.setItem('selectedTab', key);
  }, [key]);

  return (
    <Container fluid className="p-3 fs-4">
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="shippingTracking" title="Shipping Tracking">
          <ShippingTracking />
        </Tab>
        <Tab eventKey="iagTracking" title="IAG Tracking">
          <IagTracking />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default NobodyShouldKnowThis;