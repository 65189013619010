import { EventType, InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  const { instance: msalInstance, inProgress } = useMsal()
  const loginAttemptedRef = useRef(false);
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    const verify = async () => {

      if (!msalInstance.getActiveAccount() && !loginAttemptedRef.current) {
        loginAttemptedRef.current = true;
        try {
          await msalInstance.loginPopup();
        } catch (error) {
          console.error("Login failed:", error);
        }
      }

      // Optional - This will update account state if a user signs in from another tab or window
      msalInstance.enableAccountStorageEvents();

      msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
        }
      });
    };

    if (inProgress === InteractionStatus.None) {
      verify();
    }

  }, [msalInstance, inProgress]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return(
    <div>Login</div>
  )
}

export default Login
