import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Table as BTable, Button, Container, Form, FormControlProps, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { apiRequest } from "../utility/axios";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSpinner } from "../spinner/SpinnerContext";
import { RankingInfo, rankItem, compareItems } from "@tanstack/match-sorter-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notify } from "../notify/notify";
import { faCircleCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

declare module "@tanstack/react-table" {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

type IagTrackingRow = {
  fulfillmentName: string;
  iagId: string;
  iagName: string;
  status: string;
  expediteTracking: string;
  expediteTrackingStatus: string;
  startDate?: boolean;
};

type IagPipeLineStatusRes = {
  status: string;
};

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const IagTracking = () => {
  const { toggleSpinner } = useSpinner();
  const [showModal, setShowModal] = useState(false);
  const columnHelper = createColumnHelper<IagTrackingRow>();
  const [globalFilter, setGlobalFilter] = useState("");
  const [lastRunDone, setLastRunDone] = useState(false);
  const [sorting, setSorting] = useState([
    { id: "expediteTracking", desc: false },
    { id: "status", desc: false },
    { id: "iagName", desc: false },
  ]);

  // Custom sorting function for boolean values
  const booleanSort = (rowA, rowB, columnId) => {
    const a = rowA.getValue(columnId);
    const b = rowB.getValue(columnId);
    if (a === b) return 0;
    return a ? -1 : 1;
  };

  const expediteIagMutation = useMutation({
    mutationFn: async (body: { expediteTracking: boolean }) => {
      const response = await apiRequest<any>("POST", `/api/iagTracking`, body);
      return response;
    },
    onMutate: () => {
      toggleSpinner(true);
    },
    onSuccess: () => {
      setShowModal(false);
      // setCurrentShippingDetailsId(null);
      iagTrackingQuery.refetch(); // Refetch the table data on success
      iagPipeLineStatusQuery.refetch();
    },
    onError: (error) => {
      notify("error", error.message);
      toggleSpinner(false);
    },
    onSettled: () => {
      toggleSpinner(false);
    },
  });

  const handleExpediteIags = () => {
    setShowModal(true);
  };

  const handleConfirm = async () => {
    const body = {
      expediteTracking: true,
    };
    expediteIagMutation.mutate(body);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.fulfillmentName, {
        id: "fulfillmentName",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Fullfillment</span>,
        enableSorting: false,
      }),
      // columnHelper.accessor((row) => row.iagId, {
      //   id: "iagId",
      //   cell: (info) => {
      //     return <div className="text-center">{info.getValue()}</div>;
      //   },
      //   header: () => <span>IAG ID</span>,
      //   enableSorting: false,
      // }),
      columnHelper.accessor((row) => row.iagName, {
        id: "iagName",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>IAG Name</span>,
        enableSorting: true,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Status</span>,
        enableSorting: true,
      }),
      columnHelper.accessor((row) => row.expediteTracking, {
        id: "expediteTracking",
        cell: (info) => {
          let isExpedited = info.getValue();
          return (
            <>
              {isExpedited && (
                <div className="text-center">
                  <OverlayTrigger overlay={<Tooltip>Expedited</Tooltip>}>
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </OverlayTrigger>
                </div>
              )}
            </>
          );
        },
        header: () => <span>Expedite Tracking</span>,
        enableSorting: true,
      }),
      columnHelper.accessor((row) => row.expediteTrackingStatus, {
        id: "expediteTrackingStatus",
        cell: (info) => {
          const expediteId = info.getValue();
          return (
            <>
              {expediteId && (
                <div className="text-center">
                  <OverlayTrigger overlay={<Tooltip>Furture Project</Tooltip>}>
                    <Button variant="secondary">Expedite Status</Button>
                  </OverlayTrigger>
                </div>
              )}
            </>
          );
        },
        header: () => <span>Expedite Tracking Status</span>,
        enableSorting: false,
      }),
    ],
    []
  );

  const iagTrackingQuery = useQuery<IagTrackingRow[]>({
    queryKey: ["iagTrackingTable"],
    queryFn: async () => {
      const response = await apiRequest<IagTrackingRow[]>("GET", `/api/iagTracking`);
      return response;
    },
    refetchInterval: 60000 * 0.5, // Auto refetch every 30 sec
  });

  const iagPipeLineStatusQuery = useQuery<IagPipeLineStatusRes>({
    queryKey: ["iagPipeLineStatus"],
    queryFn: async () => {
      const response = await apiRequest<IagPipeLineStatusRes>("GET", `/api/iagPipeLineStatus`);
      return response;
    },
    refetchInterval: 60000 * 0.5, // Auto refetch every 30 sec
  });

  const table = useReactTable({
    data: iagTrackingQuery.data ?? [],
    columns: columns.map((column) => {
      if (column.id === "expediteTracking") {
        return {
          ...column,
          sortingFn: booleanSort,
        };
      }
      return column;
    }),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    enableMultiSort: true,
    enableSorting: true,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "fuzzy", //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      globalFilter,
      sorting,
    },
    onSortingChange: setSorting,
  });

  useEffect(() => {
    toggleSpinner(iagTrackingQuery.isPending || iagTrackingQuery.isFetching || iagTrackingQuery.isLoading);
  }, [iagTrackingQuery]);

  useEffect(() => {
    if (iagPipeLineStatusQuery?.data?.status) {
      setLastRunDone(iagPipeLineStatusQuery.data.status === "Completed");
    }
  }, [iagPipeLineStatusQuery]);

  if (!iagTrackingQuery) return <div>Loading...</div>;

  return (
    <>
      <Container fluid className="p-3 fs-4">
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="fs-4 mb-3 shadow-sm p-3"
          placeholder="Search"
          type="text"
          aria-describedby="search"
        />
        <div className="d-flex mb-3">
          <div className="d-flex gap-1 align-items-center">
            <Button size="lg" onClick={handleExpediteIags} disabled={!lastRunDone}>
              Expedite IAGs
            </Button>
            {!lastRunDone && (
              <OverlayTrigger overlay={<Tooltip>Active Expedite Job is running.</Tooltip>}>
                <FontAwesomeIcon icon={faCircleInfo} />
              </OverlayTrigger>
            )}
          </div>
        </div>
        <BTable striped bordered hover responsive size="sm">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={`text-center ${header.column.getCanSort() ? "cursor-pointer select-none" : ""}`}
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </BTable>
      </Container>

      <Modal show={showModal} onHide={handleCancel} centered={true} backdrop={"static"} style={{ fontSize: "16px" }}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to expedite IAGs tracking?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// A typical debounced input react component
// Define the props type
interface DebouncedInputProps extends Omit<FormControlProps, "onChange"> {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
}

const DebouncedInput: React.FC<DebouncedInputProps> = ({ value: initialValue, onChange, debounce = 500, ...props }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return <Form.Control {...props} value={value} onChange={handleChange} />;
};

export default IagTracking;
